// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMFXfZwOjlGLSRG0UJMrZ5r1pBn75UM7o",
  authDomain: "lillakiosken-25b5d.firebaseapp.com",
  projectId: "lillakiosken-25b5d",
  storageBucket: "lillakiosken-25b5d.appspot.com",
  messagingSenderId: "664069302760",
  appId: "1:664069302760:web:64962c4e6e46bf9dad1c89",
};

// Avoid duplicate Firebase app
let firebaseApp;

try {
  // Try to get existing app first
  firebaseApp = getApp();
  console.log("Existing Firebase app found");
} catch (error) {
  // If no app exists, initialize with config
  console.log("Creating new Firebase app");
  firebaseApp = initializeApp(firebaseConfig);
}

// Initialize Firestore and Storage
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage };
export default firebaseApp;
