import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="container">
        <h1 className="page-title">Om Lilla Kiosken</h1>

        <div className="about-content">
          <div className="about-text">
            <h2>Vår Historia</h2>
            <p>
              Lilla Kiosken startades med en vision om att erbjuda högkvalitativ
              mat i en avslappnad och välkomnande miljö. Sedan starten har vi
              fokuserat på att servera de bästa smashburgarna och autentisk
              pinsa i Gråbo.
            </p>

            <h2>Vår Filosofi</h2>
            <p>
              Vi tror på att använda färska, lokala råvaror för att skapa
              minnesvärda måltidsupplevelser. Vårt mål är att varje gäst ska
              lämna Lilla Kiosken med ett leende och en önskan att komma
              tillbaka.
            </p>

            <h2>Vårt Team</h2>
            <p>
              Bakom Lilla Kiosken står ett dedikerat team som brinner för mat
              och service. Från våra kockar till vår serveringspersonal, alla
              arbetar tillsammans för att ge dig den bästa upplevelsen.
            </p>
          </div>

          <div className="about-image">
            <img
              src="/images/kiosk.jpg"
              alt="Lilla Kiosken"
              className="about-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
