import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <div className="footer-logo">
            <Link to="/">Lilla Kiosken</Link>
          </div>
          <div className="footer-address">
            <p>Monvägen 123 44343 Gråbo</p>
            <p>0302-415 40</p>
            <p>lillakiosken@gmail.com</p>
          </div>
        </div>

        <div className="social-media">
          <a
            href="https://www.facebook.com/lillakiosken"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebook className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/lillakioskengrabo"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram className="social-icon" />
          </a>
          <a
            href="https://www.tiktok.com/@lillakiosken"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TikTok"
          >
            <FaTiktok className="social-icon" />
          </a>
        </div>

        <div className="footer-nav">
          <Link to="/" className="footer-link">
            Hem
          </Link>
          <Link to="/menu" className="footer-link">
            Meny
          </Link>
          <Link to="/recensioner" className="footer-link">
            Recensioner
          </Link>
          <Link to="/utmaningar" className="footer-link">
            Utmaningar
          </Link>
          {/* <Link to="/erbjudanden" className="footer-link">
            Erbjudanden
          </Link> */}
        </div>

        <div className="copyright">
          <p>
            &copy; {currentYear} Lilla Kiosken. Alla rättigheter förbehållna.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
