import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Menu from "./pages/Menu";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ReviewsPage from "./pages/ReviewsPage";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound";
import Offers from "./pages/Offers";
import Utmaningar from "./pages/Utmaningar";
import "./App.css";
import { useAdminContext } from "./contexts/AdminContext";

// Import Admin pages
import AdminLogin from "./pages/admin/AdminLogin";
import AdminUtmaningar from "./pages/admin/AdminChallenges";
import AdminReviews from "./pages/admin/AdminReviews";
import AdminOffers from "./pages/admin/AdminOffers";
import AdminMenu from "./pages/admin/AdminMenu";

// Layout-komponent som villkorligt visar Header och Footer
const Layout = () => {
  const location = useLocation();

  // Kolla om sökvägen börjar med /admin
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      {!isAdminRoute && <Header />}
      <main className="main-content">
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="meny" element={<Menu />} />
          <Route path="galleri" element={<GalleryPage />} />
          <Route path="kontakt" element={<ContactPage />} />
          <Route path="om" element={<AboutPage />} />
          <Route path="recensioner" element={<ReviewsPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="erbjudanden" element={<Offers />} />
          <Route path="utmaningar" element={<Utmaningar />} />

          {/* Admin routes */}
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="admin/utmaningar" element={<AdminUtmaningar />} />
          <Route path="admin/recensioner" element={<AdminReviews />} />
          <Route path="admin/erbjudanden" element={<AdminOffers />} />
          <Route path="admin/meny" element={<AdminMenu />} />

          {/* Admin routes - engelska */}
          <Route path="admin/challenges" element={<AdminUtmaningar />} />
          <Route path="admin/reviews" element={<AdminReviews />} />
          <Route path="admin/offers" element={<AdminOffers />} />
          <Route path="admin/menu" element={<AdminMenu />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      {!isAdminRoute && <Footer />}
    </>
  );
};

// Komponent för att hantera navigationsförändringar
const NavigationHandler = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // När URL ändras, rensar vi bort modalklasser från body
    document.body.classList.remove("reviews-page");
    document.body.classList.remove("modal-open");
    document.body.style.position = "";
    document.body.style.overflow = "";

    // Säkerställ att alla modal-overlay element tas bort
    const overlays = document.querySelectorAll(".review-modal-overlay");
    overlays.forEach((overlay) => {
      if (overlay && overlay.parentNode) {
        overlay.parentNode.removeChild(overlay);
      }
    });

    // Scrolla till toppen av sidan vid navigation
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

function App() {
  return (
    <Router>
      <NavigationHandler>
        <Layout />
      </NavigationHandler>
    </Router>
  );
}

export default App;
