// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Firebase konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyBYwpCpN5XnDUGjVLwMY3iAUedEBUNMi50",
  authDomain: "lillakiosken-1bde4.firebaseapp.com",
  projectId: "lillakiosken-1bde4",
  storageBucket: "lillakiosken-1bde4.appspot.com",
  messagingSenderId: "1085476321562",
  appId: "1:1085476321562:web:2a03c09af7996c8ca18fcc",
  measurementId: "G-6QWKPRX723",
};

// Använd getApps() för att kontrollera om Firebase redan är initialiserad
let firebaseApp;
if (getApps().length === 0) {
  // Ingen Firebase-app har initialiserats ännu, så skapa en
  firebaseApp = initializeApp(firebaseConfig);
} else {
  // Firebase-app finns redan, återanvänd den
  firebaseApp = getApp();
}

// Initiera Firebase-tjänster
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, auth, storage, firebaseApp };
export default firebaseApp;
