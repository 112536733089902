import React from "react";
import "./ContactPage.css";
import { FaMapMarkerAlt, FaPhone, FaClock, FaEnvelope } from "react-icons/fa";

const ContactPage = () => {
  // Länk till Google Maps för adressen
  const mapLink =
    "https://www.google.com/maps/search/?api=1&query=Monvägen+123,+44343+Gråbo";

  // Telefonlänk
  const phoneLink = "tel:0302-41540";

  return (
    <div className="contact-page">
      <div className="container">
        <h1 className="page-title">Kontakta oss</h1>

        <div className="contact-content">
          <div className="contact-info">
            <div className="contact-card">
              <div className="contact-icon">
                <FaMapMarkerAlt />
              </div>
              <h3>Adress</h3>
              <p>
                <a
                  href={mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address-link"
                >
                  Monvägen 123, 44343 Gråbo
                </a>
              </p>
            </div>

            <div className="contact-card">
              <div className="contact-icon">
                <FaPhone />
              </div>
              <h3>Telefon</h3>
              <p>
                <a href={phoneLink} className="phone-link">
                  0302-415 40
                </a>
              </p>
            </div>

            <div className="contact-card">
              <div className="contact-icon">
                <FaClock />
              </div>
              <h3>Öppettider</h3>
              <p>
                Mån-Fre: 11:30 - 20:00
                <br />
                Lör-Sön: 13:00 - 20:00
              </p>
            </div>

            <div className="contact-card">
              <div className="contact-icon">
                <FaEnvelope />
              </div>
              <h3>E-post</h3>
              <p>
                <a href="mailto:info@lillakiosken.se" className="email-link">
                  info@lillakiosken.se
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
