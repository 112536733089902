import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import AdminNav from "../../components/admin/AdminNav";
import "./AdminDashboard.css";
import "./AdminChallenges.css";

const AdminChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [completions, setCompletions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Förenklade formulärdata - ett enda namn-fält istället för förnamn/efternamn
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    time: "",
    challenge: "",
  });

  // State för att hantera formuläret
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchChallenges();
        fetchCompletions();
      } else {
        navigate("/admin/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchChallenges = async () => {
    try {
      const challengesCollection = collection(db, "challenges");
      const querySnapshot = await getDocs(challengesCollection);
      const challengesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChallenges(challengesList);
    } catch (error) {
      console.error("Error fetching challenges:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompletions = async () => {
    try {
      const completionsQuery = query(
        collection(db, "completions"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(completionsQuery);
      const completionsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date
          ? new Date(doc.data().date.toDate()).toLocaleDateString("sv-SE")
          : "Okänt datum",
      }));
      setCompletions(completionsList);
    } catch (error) {
      console.error("Error fetching completions:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.challenge) {
      alert("Namn och utmaning måste fyllas i!");
      return;
    }

    try {
      const selectedChallenge = challenges.find(
        (c) => c.id === formData.challenge
      );

      if (!selectedChallenge) {
        alert("Välj en giltig utmaning");
        return;
      }

      // Skapa nytt dokument i completions-samlingen
      await addDoc(collection(db, "completions"), {
        playerName: formData.name,
        city: formData.city || "",
        time: formData.time || "",
        challengeId: formData.challenge,
        challengeName: selectedChallenge.name,
        challengeDifficulty: selectedChallenge.difficulty,
        date: serverTimestamp(),
      });

      // Återställ formuläret
      setFormData({
        name: "",
        city: "",
        time: "",
        challenge: "",
      });

      setShowForm(false);
      alert("Utmaning registrerad!");
      fetchCompletions(); // Uppdatera listan
    } catch (error) {
      console.error("Error adding completion:", error);
      alert(`Fel vid registrering av utmaning: ${error.message}`);
    }
  };

  const handleDelete = async (id) => {
    if (
      window.confirm(
        "Är du säker på att du vill ta bort denna genomförda utmaning?"
      )
    ) {
      try {
        await deleteDoc(doc(db, "completions", id));
        setCompletions((prev) => prev.filter((c) => c.id !== id));
        alert("Utmaning borttagen!");
      } catch (error) {
        console.error("Error deleting completion:", error);
        alert(`Fel vid borttagning: ${error.message}`);
      }
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await auth.signOut();
      sessionStorage.removeItem("adminToken");
      sessionStorage.removeItem("adminEmail");
      navigate("/admin/login");
    } catch (error) {
      console.error("Utloggningsfel:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="admin-loading-container">
        <div className="admin-loading">
          <div className="loading-spinner"></div>
          <span>Laddar utmaningar...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <AdminNav />
      <div className="admin-content">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
          </svg>
          Genomförda Utmaningar
        </h2>

        <div className="completions-section">
          <div className="completions-header">
            <h3>Wall of Fame - Genomförda utmaningar</h3>
            <button
              className="add-completion-button"
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? "Avbryt" : "Lägg till genomförd utmaning"}
            </button>
          </div>

          {showForm && (
            <div className="add-completion-form">
              <h4>Registrera genomförd utmaning</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="challenge">Utmaning: *</label>
                    <select
                      id="challenge"
                      name="challenge"
                      value={formData.challenge}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Välj utmaning</option>
                      {challenges.map((challenge) => (
                        <option key={challenge.id} value={challenge.id}>
                          {challenge.name} ({challenge.difficulty})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">Namn: *</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="T.ex. Anna Andersson"
                      required
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="city">Stad:</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      placeholder="T.ex. Stockholm"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="time">Tid:</label>
                    <input
                      type="text"
                      id="time"
                      name="time"
                      value={formData.time}
                      onChange={handleInputChange}
                      placeholder="T.ex. 2 min 30 sek"
                    />
                  </div>
                </div>

                <div className="form-buttons">
                  <button type="submit" className="submit-button">
                    Spara
                  </button>
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setShowForm(false)}
                  >
                    Avbryt
                  </button>
                </div>
              </form>
            </div>
          )}

          <div className="completions-table-container">
            <table className="completions-table">
              <thead>
                <tr>
                  <th>Namn</th>
                  <th>Stad</th>
                  <th>Utmaning</th>
                  <th>Tid</th>
                  <th>Datum</th>
                  <th>Åtgärder</th>
                </tr>
              </thead>
              <tbody>
                {completions.length > 0 ? (
                  completions.map((completion) => (
                    <tr key={completion.id}>
                      <td>{completion.playerName}</td>
                      <td>{completion.city || "-"}</td>
                      <td
                        className={`challenge-${completion.challengeDifficulty?.toLowerCase()}`}
                      >
                        {completion.challengeName}
                      </td>
                      <td>{completion.time || "-"}</td>
                      <td>{completion.date}</td>
                      <td>
                        <button
                          className="delete-button"
                          onClick={() => handleDelete(completion.id)}
                          title="Ta bort"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                          Ta bort
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="no-completions">
                      Inga genomförda utmaningar finns registrerade ännu.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChallenges;
