import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import logoImage from "../assets/images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const logoElements = document.querySelectorAll(".logo-image");
    logoElements.forEach((el) => {
      el.style.height = "120px";
      el.style.width = "auto";
      el.style.maxHeight = "none";
      el.style.minHeight = "120px";
    });
  }, []);

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/" className="logo" onClick={closeMenu}>
            <img
              src="/images/logo.png"
              alt="Lilla Kiosken"
              className="logo-image"
              style={{
                height: "120px",
                width: "auto",
                display: "block",
                marginBottom: "0",
              }}
            />
          </Link>
        </div>

        <button
          className="mobile-menu-button"
          onClick={toggleMenu}
          aria-label={mobileMenuOpen ? "Stäng meny" : "Öppna meny"}
        >
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>

        <nav className={`nav ${mobileMenuOpen ? "active" : ""}`}>
          <ul className="nav-list">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" onClick={closeMenu}>
                Hem
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/recensioner"
                className="nav-link"
                onClick={closeMenu}
              >
                Recensioner
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/utmaningar"
                className="nav-link"
                onClick={closeMenu}
              >
                Utmaningar
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/contact" className="nav-link" onClick={closeMenu}>
                Kontakt
              </NavLink>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
