import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db, auth } from "../../firebase/config";
import AdminNav from "../../components/admin/AdminNav";
import "./AdminDashboard.css";
import "./AdminReviews.css";

const starStyles = `
  .admin-star.filled, 
  .admin-star.filled span {
    color: #FFD700 !important;
    -webkit-text-fill-color: #FFD700 !important;
  }
  
  .admin-star.empty, 
  .admin-star.empty span {
    color: #d3d3d3 !important;
    -webkit-text-fill-color: #d3d3d3 !important;
  }
`;

const AdminReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterRating, setFilterRating] = useState(0);
  const [sortOrder, setSortOrder] = useState({
    field: "date",
    direction: "desc",
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("AdminReviews is mounting");

    const auth = getAuth();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User is authenticated:", user.email);
        fetchReviews();
      } else {
        console.log("No authenticated user, redirecting to login");
        navigate("/admin/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const reviewsRef = collection(db, "reviews");
      const q = query(reviewsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const reviewsData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        reviewsData.push({
          id: doc.id,
          name: data.name || "",
          rating: Number(data.rating) || 5,
          comment: data.comment || "",
          createdAt: data.createdAt?.toDate() || new Date(),
        });
      });

      setReviews(reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      alert("Kunde inte hämta recensioner");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (reviewId) => {
    if (
      !window.confirm("Är du säker på att du vill ta bort denna recension?")
    ) {
      return;
    }

    try {
      await deleteDoc(doc(db, "reviews", reviewId));
      await fetchReviews(); // Uppdatera listan efter borttagning
      alert("Recensionen har tagits bort");
    } catch (error) {
      console.error("Error deleting review:", error);
      alert("Kunde inte ta bort recensionen");
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await auth.signOut();
      sessionStorage.removeItem("adminToken");
      sessionStorage.removeItem("adminEmail");
      navigate("/admin/login");
    } catch (error) {
      console.error("Utloggningsfel:", error);
    }
  };

  const handleSort = (field) => {
    setSortOrder((prev) => {
      if (prev.field === field) {
        return {
          field,
          direction: prev.direction === "asc" ? "desc" : "asc",
        };
      }
      return { field, direction: "desc" };
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("sv-SE", options);
  };

  const renderStars = (rating) => {
    const stars = [];
    const ratingNum = Number(rating);

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`admin-star ${i <= ratingNum ? "filled" : "empty"}`}
          style={{
            display: "inline-block",
            fontSize: "18px",
            color: i <= ratingNum ? "#FFD700" : "#d3d3d3",
            marginRight: "2px",
            textShadow: "none",
          }}
        >
          <span
            style={{
              color: i <= ratingNum ? "#FFD700" : "#d3d3d3",
              fontWeight: "bold",
            }}
          >
            {i <= ratingNum ? "★" : "☆"}
          </span>
        </span>
      );
    }

    return (
      <div
        className="admin-stars-container"
        style={{
          display: "inline-block",
          minWidth: "90px",
        }}
      >
        {stars}
      </div>
    );
  };

  // Filtrering och sortering av recensioner
  const filteredReviews = reviews
    .filter(
      (review) =>
        (filterRating === 0 || review.rating === filterRating) &&
        (searchTerm === "" ||
          review.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          review.comment?.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      const fieldA =
        sortOrder.field === "date"
          ? new Date(a[sortOrder.field])
          : a[sortOrder.field];
      const fieldB =
        sortOrder.field === "date"
          ? new Date(b[sortOrder.field])
          : b[sortOrder.field];

      if (fieldA < fieldB) return sortOrder.direction === "asc" ? -1 : 1;
      if (fieldA > fieldB) return sortOrder.direction === "asc" ? 1 : -1;
      return 0;
    });

  // Statistik
  const getAverageRating = () => {
    if (reviews.length === 0) return 0;
    const sum = reviews.reduce(
      (total, review) => total + (review.rating || 0),
      0
    );
    return (sum / reviews.length).toFixed(1);
  };

  const getRatingDistribution = () => {
    const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    reviews.forEach((review) => {
      if (review.rating) {
        distribution[review.rating]++;
      }
    });
    return distribution;
  };

  const ratingDistribution = getRatingDistribution();

  if (loading) {
    return (
      <div className="admin-loading-container">
        <div className="admin-loading">
          <div className="loading-spinner"></div>
          <span>Laddar recensioner...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-page">
        <AdminNav />
        <div className="admin-content">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <AdminNav />
      <style>{starStyles}</style>
      <div className="admin-reviews">
        <div className="admin-container">
          <header className="admin-header">
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              Lilla Kiosken Admin
            </h1>

            <div className="admin-user-info">
              <div className="admin-user-email">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                {sessionStorage.getItem("adminEmail")}
              </div>
              <button onClick={handleLogout} className="logout-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                Logga ut
              </button>
            </div>

            <nav className="admin-nav">
              <ul>
                <li>
                  <Link
                    to="/admin/reviews"
                    className={
                      location.pathname === "/admin/reviews" ? "active" : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                    </svg>
                    Recensioner
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/utmaningar"
                    className={
                      location.pathname === "/admin/utmaningar" ? "active" : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                    </svg>
                    Utmaningar
                  </Link>
                </li>
              </ul>
            </nav>
          </header>

          <main className="admin-content">
            <h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
              </svg>
              Hantera Recensioner
            </h2>

            <div className="admin-stats-container">
              <div className="admin-stat-card">
                <div className="stat-title">Totalt antal recensioner</div>
                <div className="stat-value">{reviews.length}</div>
              </div>
              <div className="admin-stat-card">
                <div className="stat-title">Genomsnittligt betyg</div>
                <div className="stat-value">
                  {getAverageRating()}
                  <span className="stat-stars">
                    {renderStars(Math.round(getAverageRating()))}
                  </span>
                </div>
              </div>
              <div className="admin-stat-card rating-distribution">
                <div className="stat-title">Betygsfördelning</div>
                <div className="rating-bars">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <div key={rating} className="rating-bar-container">
                      <div className="rating-label">{rating} ★</div>
                      <div className="rating-bar-wrapper">
                        <div
                          className="rating-bar"
                          style={{
                            width: `${
                              reviews.length
                                ? (ratingDistribution[rating] /
                                    reviews.length) *
                                  100
                                : 0
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div className="rating-count">
                        {ratingDistribution[rating]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="admin-filters">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Sök efter namn eller kommentar..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="admin-search"
                />
                <svg
                  className="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>

                {searchTerm && (
                  <button
                    className="clear-search"
                    onClick={() => setSearchTerm("")}
                  >
                    ×
                  </button>
                )}
              </div>

              <div className="rating-filter">
                <span className="filter-label">Filtrera betyg:</span>
                <div className="rating-buttons">
                  <button
                    className={filterRating === 0 ? "active" : ""}
                    onClick={() => setFilterRating(0)}
                  >
                    Alla
                  </button>
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <button
                      key={rating}
                      className={filterRating === rating ? "active" : ""}
                      onClick={() => setFilterRating(rating)}
                    >
                      {rating} ★
                    </button>
                  ))}
                </div>
              </div>

              <button className="refresh-button" onClick={fetchReviews}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M23 4v6h-6"></path>
                  <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                </svg>
                Uppdatera
              </button>
            </div>

            <div className="reviews-table-container">
              <table className="reviews-table">
                <thead>
                  <tr>
                    <th
                      className={
                        sortOrder.field === "name"
                          ? `sortable ${sortOrder.direction}`
                          : "sortable"
                      }
                      onClick={() => handleSort("name")}
                    >
                      Namn
                      <svg
                        className="sort-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="7 13 12 18 17 13"></polyline>
                        <polyline points="7 6 12 11 17 6"></polyline>
                      </svg>
                    </th>
                    <th
                      className={
                        sortOrder.field === "rating"
                          ? `sortable ${sortOrder.direction}`
                          : "sortable"
                      }
                      onClick={() => handleSort("rating")}
                    >
                      Betyg
                      <svg
                        className="sort-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="7 13 12 18 17 13"></polyline>
                        <polyline points="7 6 12 11 17 6"></polyline>
                      </svg>
                    </th>
                    <th>Kommentar</th>
                    <th
                      className={
                        sortOrder.field === "date"
                          ? `sortable ${sortOrder.direction}`
                          : "sortable"
                      }
                      onClick={() => handleSort("date")}
                    >
                      Datum
                      <svg
                        className="sort-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="7 13 12 18 17 13"></polyline>
                        <polyline points="7 6 12 11 17 6"></polyline>
                      </svg>
                    </th>
                    <th>Åtgärder</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReviews.length > 0 ? (
                    filteredReviews.map((review) => (
                      <tr key={review.id}>
                        <td className="review-name">
                          {review.name || "Anonym"}
                        </td>
                        <td className="review-rating">
                          <div
                            className={`rating-badge rating-${review.rating}`}
                          >
                            {renderStars(review.rating)}
                          </div>
                        </td>
                        <td className="review-comment">{review.comment}</td>
                        <td className="review-date">
                          {formatDate(review.createdAt.toISOString())}
                        </td>
                        <td className="review-actions">
                          <button
                            className="review-delete-btn"
                            onClick={() => handleDelete(review.id)}
                            title="Ta bort recension"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            Ta bort
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="no-reviews">
                        {searchTerm || filterRating !== 0
                          ? "Inga recensioner matchar dina filter. Ändra sökningen eller återställ filtren."
                          : "Inga recensioner hittades. Recensioner kommer att visas här när kunder skickar in dem."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminReviews;
