import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import { signOut } from "firebase/auth";
import "./AdminNav.css";

const AdminNav = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/admin/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <div className="admin-nav-wrapper">
      <nav className="admin-nav">
        <div className="nav-links">
          <NavLink
            to="/admin"
            end
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/admin/menu"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Meny
          </NavLink>
          <NavLink
            to="/admin/offers"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Erbjudanden
          </NavLink>
          <NavLink
            to="/admin/challenges"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Utmaningar
          </NavLink>
          <NavLink
            to="/admin/reviews"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Recensioner
          </NavLink>
        </div>
        <button onClick={handleLogout} className="logout-button">
          Logga ut
        </button>
      </nav>
    </div>
  );
};

export default AdminNav;
