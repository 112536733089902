import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "./LoginPage.css";
import { useAdminContext } from "../contexts/AdminContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { isLoggedIn, login } = useAdminContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!username || !password) {
      setError("Vänligen fyll i alla fält");
      return;
    }

    // Enkel inloggning för demonstrationssyfte
    // I en produktionsmiljö skulle detta göras säkrare med kryptering och server-validering
    if (username === "admin" && password === "admin123") {
      login();
    } else {
      setError("Fel användarnamn eller lösenord");
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <h1 className="login-title">Admin Inloggning</h1>

        <form className="login-form" onSubmit={handleSubmit}>
          {error && <div className="login-error">{error}</div>}

          <div className="form-group">
            <label htmlFor="username">Användarnamn</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
              placeholder="Ange användarnamn"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Lösenord</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
              placeholder="Ange lösenord"
            />
          </div>

          <button type="submit" className="login-button">
            Logga in
          </button>
        </form>

        <div className="login-info">
          <p>
            För demo: använd "admin" som användarnamn och "admin123" som
            lösenord.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
