// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQ3vGrNS6dtwpj2p1t1BJHjFgA49rwzas",
  authDomain: "lilla-kiosken.firebaseapp.com",
  projectId: "lilla-kiosken",
  storageBucket: "lilla-kiosken.appspot.com",
  messagingSenderId: "827182283798",
  appId: "1:827182283798:web:4de7b026104cb57af54937",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialisera tjänster
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Exportera en gång med alla tjänster
export { db, auth, storage };
