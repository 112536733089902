import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import { db, storage } from "../../config/firebase";
import AdminNav from "../../components/admin/AdminNav";
import "./AdminOffers.css";

const AdminOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  // Form states
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      setLoading(true);
      const q = query(collection(db, "offers"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const offersList = [];
      querySnapshot.forEach((doc) => {
        offersList.push({ id: doc.id, ...doc.data() });
      });

      setOffers(offersList);
    } catch (error) {
      console.error("Error fetching offers:", error);
      alert("Det gick inte att hämta erbjudanden. Försök igen senare.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setPrice("");
    setRegularPrice("");
    setValidUntil("");
    setIsActive(true);
    setEditId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Spara-knapp klickad");

    try {
      const offerData = {
        title: title || "Nytt erbjudande",
        description: description || "",
        price: price ? Number(price) : null,
        regularPrice: regularPrice ? Number(regularPrice) : null,
        validUntil: validUntil ? new Date(validUntil) : null,
        active: isActive === true,
        updatedAt: serverTimestamp(),
      };

      console.log("Sparar erbjudandedata:", offerData);

      if (editId) {
        await updateDoc(doc(db, "offers", editId), offerData);
        alert("Erbjudandet har uppdaterats!");
      } else {
        offerData.createdAt = serverTimestamp();
        await addDoc(collection(db, "offers"), offerData);
        alert("Nytt erbjudande har lagts till!");
      }

      resetForm();
      setShowForm(false);
      fetchOffers();
    } catch (error) {
      console.error("Fel vid sparande av erbjudande:", error);
      alert("Det gick inte att spara erbjudandet: " + error.message);
    }
  };

  const handleEdit = (offer) => {
    setEditId(offer.id);
    setTitle(offer.title);
    setDescription(offer.description);
    setPrice(offer.price || "");
    setRegularPrice(offer.regularPrice || "");

    if (offer.validUntil) {
      const date = offer.validUntil.toDate
        ? offer.validUntil.toDate()
        : new Date(offer.validUntil);
      setValidUntil(date.toISOString().split("T")[0]);
    } else {
      setValidUntil("");
    }

    setIsActive(offer.active);
    setShowForm(true);
  };

  const handleDelete = async (id, imageUrl) => {
    if (
      !window.confirm("Är du säker på att du vill ta bort detta erbjudande?")
    ) {
      return;
    }

    try {
      // Delete the document
      await deleteDoc(doc(db, "offers", id));

      // Delete the image if exists
      if (imageUrl) {
        try {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef);
        } catch (imageError) {
          console.error("Could not delete image:", imageError);
        }
      }

      // Update the state
      setOffers(offers.filter((offer) => offer.id !== id));
      alert("Erbjudandet har tagits bort!");
    } catch (error) {
      console.error("Error deleting offer:", error);
      alert("Det gick inte att ta bort erbjudandet. Försök igen senare.");
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "Inget datum";

    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return new Intl.DateTimeFormat("sv-SE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  if (loading) {
    return (
      <div className="admin-page">
        <AdminNav />
        <div className="admin-content">
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Laddar upp erbjudande...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <AdminNav />
      <div className="admin-content">
        <div className="admin-offers-container">
          <h2>Hantera Erbjudanden</h2>

          <button
            className="add-button"
            onClick={() => {
              resetForm();
              setShowForm(!showForm);
            }}
          >
            {showForm ? "Avbryt" : "Lägg till nytt erbjudande"}
          </button>

          {showForm && (
            <div className="offer-form-container">
              <h2>
                {editId ? "Redigera erbjudande" : "Lägg till nytt erbjudande"}
              </h2>
              <form onSubmit={handleSubmit} className="offer-form">
                <div className="form-group">
                  <label htmlFor="title">Titel</label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Beskrivning</label>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="4"
                  />
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="price">Pris (kr)</label>
                    <input
                      type="number"
                      id="price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      min="0"
                      step="1"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="regularPrice">Ordinarie pris (kr)</label>
                    <input
                      type="number"
                      id="regularPrice"
                      value={regularPrice}
                      onChange={(e) => setRegularPrice(e.target.value)}
                      min="0"
                      step="1"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="validUntil">Giltigt till</label>
                  <input
                    type="date"
                    id="validUntil"
                    value={validUntil}
                    onChange={(e) => setValidUntil(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="active" className="checkbox-label">
                    <input
                      type="checkbox"
                      id="active"
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                    Aktivt erbjudande
                  </label>
                </div>

                <div className="form-buttons">
                  <button
                    type="button"
                    onClick={() => {
                      resetForm();
                      setShowForm(false);
                    }}
                    className="cancel-button"
                  >
                    Avbryt
                  </button>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("Spara-knapp klickad");
                      handleSubmit(e);
                    }}
                    className="save-button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#28a745",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "4px",
                      fontWeight: "bold",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {editId ? "Uppdatera" : "Spara"}
                  </button>
                </div>
              </form>
            </div>
          )}

          <div
            style={{
              marginTop: "20px",
              padding: "15px",
              backgroundColor: "#f8f8f8",
              borderRadius: "5px",
            }}
          >
            <div className="offers-grid">
              {offers.map((offer) => (
                <div
                  key={offer.id}
                  className={`offer-item ${!offer.active ? "inactive" : ""}`}
                >
                  <div className="offer-details">
                    <div className="offer-info">
                      <h2>{offer.title}</h2>
                      <p>{offer.description}</p>
                      {offer.price && (
                        <p className="offer-price-admin">
                          <strong>Pris:</strong> {offer.price} kr
                          {offer.regularPrice &&
                            ` (Ord. ${offer.regularPrice} kr)`}
                        </p>
                      )}
                      {offer.validUntil && (
                        <p>
                          <strong>Giltigt till:</strong>{" "}
                          {formatDate(offer.validUntil)}
                        </p>
                      )}
                      <p>
                        <strong>Status:</strong>{" "}
                        {offer.active ? "Aktiv" : "Inaktiv"}
                      </p>
                    </div>
                  </div>
                  <div className="offer-actions">
                    <button
                      onClick={() => handleEdit(offer)}
                      className="edit-button"
                    >
                      Redigera
                    </button>
                    <button
                      onClick={() => handleDelete(offer.id, offer.imageUrl)}
                      className="delete-button"
                    >
                      Ta bort
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOffers;
