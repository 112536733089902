import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, auth, storage } from "../../firebase/config";
import "./AdminMenu.css";
import AdminNav from "../../components/admin/AdminNav";
import { useNavigate } from "react-router-dom";

const AdminMenu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [newItem, setNewItem] = useState({
    name: "",
    price: "",
    weight: "",
    description: "",
    category: "smashburger",
    imageUrl: "",
  });

  useEffect(() => {
    // Kontrollera autentisering
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/admin/login");
        return;
      }
      // Om användaren är inloggad, hämta menydata
      fetchMenuItems();
    });

    return () => {
      unsubscribeAuth();
    };
  }, [navigate]);

  const fetchMenuItems = async () => {
    try {
      const menuSnapshot = await getDocs(collection(db, "menu"));
      const items = menuSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMenuItems(items);
    } catch (error) {
      console.error("Error fetching menu items:", error);
      alert("Kunde inte hämta menyartiklar");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        // 5MB gräns
        alert("Bilden är för stor. Max storlek är 5MB.");
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    try {
      console.log("Börjar ladda upp bild...");
      const timestamp = Date.now();
      const fileName = `${timestamp}_${file.name.replace(
        /[^a-zA-Z0-9.]/g,
        "_"
      )}`;
      const storageRef = ref(storage, `menu-images/${fileName}`);

      console.log("Laddar upp till Firebase Storage...");
      const snapshot = await uploadBytes(storageRef, file);
      console.log("Bild uppladdad, hämtar URL...");

      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log("Bild URL hämtad:", downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Fel vid bilduppladdning:", error);
      throw new Error(`Kunde inte ladda upp bilden: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Börjar lägga till ny artikel...");
      let imageUrl = "";

      if (selectedImage) {
        console.log("Laddar upp bild...");
        imageUrl = await uploadImage(selectedImage);
        console.log("Bild uppladdad:", imageUrl);
      }

      const itemToAdd = {
        name: newItem.name.trim(),
        price: Number(newItem.price),
        weight: newItem.weight ? Number(newItem.weight) : null,
        description: newItem.description.trim(),
        category: newItem.category,
        imageUrl,
        createdAt: new Date(),
      };

      console.log("Lägger till i Firestore:", itemToAdd);
      const docRef = await addDoc(collection(db, "menu"), itemToAdd);
      console.log("Artikel tillagd med ID:", docRef.id);

      // Återställ formuläret
      setNewItem({
        name: "",
        price: "",
        weight: "",
        description: "",
        category: "smashburger",
        imageUrl: "",
      });
      setSelectedImage(null);
      setImagePreview(null);

      await fetchMenuItems();
      alert("Menyartikel har lagts till!");
    } catch (error) {
      console.error("Fel vid tillägg av artikel:", error);
      alert(`Kunde inte lägga till artikel: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, imageUrl) => {
    if (!window.confirm("Är du säker på att du vill ta bort denna artikel?"))
      return;

    try {
      await deleteDoc(doc(db, "menu", id));

      if (imageUrl) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }

      await fetchMenuItems();
      alert("Artikel har tagits bort!");
    } catch (error) {
      console.error("Error deleting menu item:", error);
      alert("Kunde inte ta bort artikeln");
    }
  };

  const categories = [
    { id: "smashburger", name: "Smash Burger" },
    { id: "pinsa", name: "Pinsa" },
    { id: "drinks", name: "Dricka" },
    { id: "sides", name: "Tillbehör" },
    { id: "dips", name: "Dipp" },
  ];

  if (loading) {
    return (
      <div className="admin-page">
        <AdminNav />
        <div className="admin-content">
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Laddar upp artikel...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-page">
        <AdminNav />
        <div className="admin-content">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <AdminNav />
      <div className="admin-content">
        <div className="admin-menu-container">
          <h2>Hantera Meny</h2>

          <form onSubmit={handleSubmit} className="menu-form">
            <select
              value={newItem.category}
              onChange={(e) =>
                setNewItem({ ...newItem, category: e.target.value })
              }
              required
            >
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>

            <input
              type="text"
              placeholder="Namn"
              value={newItem.name}
              onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
              required
            />

            <input
              type="number"
              placeholder="Pris (kr)"
              value={newItem.price}
              onChange={(e) =>
                setNewItem({ ...newItem, price: e.target.value })
              }
              required
            />

            <input
              type="number"
              placeholder="Gram (valfritt)"
              value={newItem.weight}
              onChange={(e) =>
                setNewItem({ ...newItem, weight: e.target.value })
              }
            />

            <textarea
              placeholder="Beskrivning"
              value={newItem.description}
              onChange={(e) =>
                setNewItem({ ...newItem, description: e.target.value })
              }
              rows="3"
            />

            <div className="image-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="file-input"
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                </div>
              )}
            </div>

            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Laddar upp..." : "Lägg till artikel"}
            </button>
          </form>

          <div className="menu-items-list">
            {categories.map((category) => (
              <div key={category.id} className="category-section">
                <h3>{category.name}</h3>
                <div className="items-grid">
                  {menuItems
                    .filter((item) => item.category === category.id)
                    .map((item) => (
                      <div key={item.id} className="menu-item-card">
                        {item.imageUrl && (
                          <div className="item-image">
                            <img
                              src={item.imageUrl}
                              alt={item.name}
                              onError={(e) => (e.target.style.display = "none")}
                            />
                          </div>
                        )}
                        <div className="item-content">
                          <h4>{item.name}</h4>
                          <p className="price">{item.price} kr</p>
                          {item.weight && (
                            <p className="weight">{item.weight}g</p>
                          )}
                          {item.description && (
                            <p className="description">{item.description}</p>
                          )}
                          <button
                            onClick={() => handleDelete(item.id, item.imageUrl)}
                            className="delete-button"
                          >
                            Ta bort
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMenu;
