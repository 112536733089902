import React from "react";
import "./GalleryPage.css";

const GalleryPage = () => {
  return (
    <div className="gallery-page">
      <div className="container">
        <h1 className="page-title">Galleri</h1>
        <div className="gallery-content">
          <p>Här kommer vårt bildgalleri snart att finnas.</p>
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
