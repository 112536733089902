import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "100px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1>404 - Sidan hittades inte</h1>
      <p>Sidan du letar efter finns inte eller har flyttats.</p>
      <div style={{ marginTop: "20px" }}>
        <Link
          to="/"
          style={{
            display: "inline-block",
            padding: "10px 20px",
            backgroundColor: "#3498db",
            color: "white",
            textDecoration: "none",
            borderRadius: "4px",
          }}
        >
          Tillbaka till startsidan
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
