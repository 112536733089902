import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase/config";
import {
  FaUtensils,
  FaMapMarkerAlt,
  FaPhone,
  FaClock,
  FaShoppingCart,
  FaStar,
  FaInstagram,
} from "react-icons/fa";
import "./HomePage.css";

// Modal component som renderas direkt på body
const Modal = ({ onClose, children, className = "" }) => {
  // Skapa en portal för att rendera modalen utanför vanliga DOM-flödet
  return ReactDOM.createPortal(
    <div className={`review-modal-overlay ${className}`} onClick={onClose}>
      {children}
    </div>,
    document.body
  );
};

const HomePage = () => {
  // Länk till ditt externa beställningssystem
  const externalOrderSystem =
    "https://app.ordine.se/r/lilla-kiosken-grabo/deliveryTypes";

  // Separat länk för takeaway-beställning
  const takeawayOrderSystem =
    "https://app.ordine.se/r/lilla-kiosken-grabo/deliveryTypes";

  // Länk till Google Maps för adressen
  const mapLink =
    "https://www.google.com/maps/search/?api=1&query=Monvägen+123,+44343+Gråbo";

  // Telefonlänk
  const phoneLink = "tel:0302-41540"; // Tagit bort mellanslaget för att fungera korrekt

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [modalReview, setModalReview] = useState(null);
  const [offers, setOffers] = useState([]);
  const [offersLoading, setOffersLoading] = useState(true);

  useEffect(() => {
    // Skapa ett slumpmässigt frö vid varje sidladdning
    const randomSeed = Math.random().toString(36).substring(2, 15);
    console.log("Ny sidladdning med slumpfrö:", randomSeed);

    fetchReviews();
    fetchOffers();

    // Lyssna även på window.onload för omuppdateringar
    const handleReload = () => {
      console.log("Sidan uppdaterad, hämtar nya slumpmässiga recensioner");
      fetchReviews();
    };

    window.addEventListener("load", handleReload);

    // Sätt reviews-page klass på body ENDAST för recensionssidan
    // Försäkra att det inte finns några modala overlay från tidigare
    const existingOverlays = document.querySelectorAll(".review-modal-overlay");
    existingOverlays.forEach((overlay) => {
      if (overlay && overlay.parentNode) {
        overlay.parentNode.removeChild(overlay);
      }
    });

    return () => {
      window.removeEventListener("load", handleReload);
      // Återställ body-stilar vid unmount
      document.body.classList.remove("reviews-page");
      document.body.classList.remove("modal-open");
      document.body.style.position = "";
      document.body.style.overflow = "";

      // Säkerställ att alla modal-overlay element tas bort
      const overlays = document.querySelectorAll(".review-modal-overlay");
      overlays.forEach((overlay) => {
        overlay.style.display = "none";
        overlay.style.visibility = "hidden";
        overlay.style.opacity = "0";
        overlay.style.pointerEvents = "none";

        // Om möjligt, ta bort elementet helt
        if (overlay.parentNode) {
          overlay.parentNode.removeChild(overlay);
        }
      });
    };
  }, []);

  const handleOpenModal = (review) => {
    console.log("Öppnar modal för recension:", review.id);

    // Lägg till reviews-page klass på body
    document.body.classList.add("reviews-page");
    document.body.classList.add("modal-open");

    // Viktigt: Förhindra låsning av body position men tillåt scroll att återupptas senare
    document.body.style.position = "static";
    document.body.style.overflow = "hidden";

    // Lagra nuvarande scroll-position för att återställa senare
    document.body.setAttribute(
      "data-scroll-position",
      window.pageYOffset.toString()
    );

    setModalReview(review);
  };

  const handleCloseModal = () => {
    console.log("Stänger modal");

    // Ta bort modal-open men behåll reviews-page klass
    document.body.classList.remove("modal-open");

    // Återställ body-stilar
    document.body.style.position = "";
    document.body.style.overflow = "";

    // Återställ scroll-positionen
    const scrollY = document.body.getAttribute("data-scroll-position");
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY));
    }

    setModalReview(null);
  };

  const fetchReviews = async () => {
    setLoading(false);
    setRefreshing(true);
    try {
      // Hämta alla recensioner från Firestore istället för bara de tre senaste
      const reviewsRef = collection(db, "reviews");
      const q = query(reviewsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const allReviewsData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        allReviewsData.push({
          id: doc.id,
          name: data.name || "",
          rating: Number(data.rating) || 5,
          comment: data.comment || "",
          createdAt: data.createdAt?.toDate() || new Date(),
        });
      });

      // Om det finns färre än 3 recensioner, använd alla som finns
      if (allReviewsData.length <= 3) {
        console.log(
          "Bara",
          allReviewsData.length,
          "recensioner hittades, visar alla"
        );
        setReviews(allReviewsData);
      } else {
        // Slumpa fram 3 recensioner
        const randomReviews = getRandomReviews(allReviewsData, 3);
        console.log(
          "Slumpmässigt valda recensioner:",
          randomReviews.map((r) => r.id)
        );

        // Töm recensionerna först för animationseffekt
        setReviews([]);

        // Lägg till kort fördröjning för att försäkra att DOM har uppdaterats
        setTimeout(() => {
          setReviews(randomReviews);
        }, 100);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setLoading(false);
    } finally {
      // Sätt kort fördröjning för visuell feedback
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
    }
  };

  const fetchOffers = async () => {
    try {
      setOffersLoading(true);
      const offersRef = collection(db, "offers");
      const q = query(offersRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const offersData = [];
      querySnapshot.forEach((doc) => {
        const offerData = { id: doc.id, ...doc.data() };
        // Only include active offers that haven't expired
        const currentDate = new Date();
        const isExpired = offerData.validUntil
          ? (offerData.validUntil.toDate
              ? offerData.validUntil.toDate()
              : new Date(offerData.validUntil)) < currentDate
          : false;

        if (offerData.active && !isExpired) {
          offersData.push(offerData);
        }
      });

      setOffers(offersData);
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setOffersLoading(false);
    }
  };

  // Förbättrad funktion för att välja slumpmässiga recensioner - Fisher-Yates algoritm
  const getRandomReviews = (reviews, count) => {
    const shuffled = [...reviews];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, count);
  };

  // Formatera datum
  const formatDate = (dateString) => {
    if (!dateString) return "Okänt datum";

    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("sv-SE", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(date);
    } catch (e) {
      console.error("Fel vid datumformatering:", e);
      return "Okänt datum";
    }
  };

  const renderStars = (rating) => {
    // Säkerställ att rating är ett nummer mellan 1-5
    const numRating = Number(rating) || 5;
    const safeRating = Math.min(5, Math.max(1, numRating));

    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          letterSpacing: "0",
          gap: "2px",
          height: "24px",
          overflow: "visible",
          marginTop: "2px",
          marginBottom: "2px",
        }}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <div
            key={i}
            style={{
              fontSize: "16px",
              color: i <= safeRating ? "#ffd700" : "#d3d3d3",
              display: "inline-block",
              lineHeight: "1.5",
              padding: "0 2px",
              margin: "0",
              border: "none",
              backgroundColor: "transparent",
              height: "24px",
              overflow: "visible",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            ★
          </div>
        ))}
      </div>
    );
  };

  // Returnera rätt betygsetikett baserat på betyg
  const getRatingLabel = (rating) => {
    switch (rating) {
      case 1:
        return "Dåligt";
      case 2:
        return "OK";
      case 3:
        return "Bra";
      case 4:
        return "Mycket bra";
      case 5:
        return "Utmärkt";
      default:
        return "";
    }
  };

  // Hjälpfunktion för att hantera imageUrl säkert
  const getFirstImageUrl = (review) => {
    if (!review.imageUrl) return null;

    if (Array.isArray(review.imageUrl)) {
      return review.imageUrl.length > 0 ? review.imageUrl[0] : null;
    } else {
      return review.imageUrl; // Returnera strängen
    }
  };

  return (
    <div className="home-container">
      {/* Hero-sektion med bild som sträcker sig ut till kanterna */}
      <section className="banner-section fullwidth-banner">
        <img src="/images/hem.jpg" alt="Lilla Kiosken" className="banner-img" />
        <div className="banner-content">
          <h1>Välkommen till Lilla Kiosken</h1>
          <p>
            Den lilla kiosken med den stora smaken. Ditt självklara val för god
            mat och gemenskap
          </p>
          <a
            href={externalOrderSystem}
            target="_blank"
            rel="noopener noreferrer"
            className="banner-button"
          >
            Beställ nu
          </a>
        </div>
      </section>

      {/* Erbjudanden */}
      <section className="offers-section">
        <div className="container">
          <h2 className="section-title">Våra Erbjudanden</h2>
          {offersLoading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Laddar erbjudanden...</p>
            </div>
          ) : offers.length === 0 ? (
            <div className="no-offers">
              <p>Det finns inga aktuella erbjudanden just nu.</p>
              <p>Kom tillbaka senare för att se våra senaste erbjudanden!</p>
            </div>
          ) : (
            <div className="offers-grid">
              {offers.map((offer) => (
                <div key={offer.id} className="offer-card">
                  {offer.imageUrl && (
                    <div className="offer-image">
                      <img src={offer.imageUrl} alt={offer.title} />
                    </div>
                  )}
                  <div className="offer-content">
                    <h3>{offer.title}</h3>
                    <p className="offer-description">{offer.description}</p>
                    {offer.price && (
                      <p className="offer-price">
                        <span className="price-label">Pris:</span> {offer.price}{" "}
                        kr
                        {offer.regularPrice && (
                          <span className="regular-price">
                            {" "}
                            (Ord. {offer.regularPrice} kr)
                          </span>
                        )}
                      </p>
                    )}
                    {offer.validUntil && (
                      <p className="offer-validity">
                        Giltigt till:{" "}
                        {new Date(
                          offer.validUntil.seconds * 1000
                        ).toLocaleDateString("sv-SE")}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Om Lilla Kiosken */}
      <section className="about-section">
        <div className="container">
          <h2 className="section-title">Om Lilla Kiosken</h2>
          <div className="about-content">
            <div className="about-text">
              <p style={{ color: "white" }}>
                Välkommen till Lilla Kiosken! Vi är en familjeägd restaurang som
                serverar högkvalitativ mat med de bästa råvarorna. Vår passion
                är att skapa en minnesvärd måltidsupplevelse för alla våra
                gäster.
              </p>
              <p>
                Sedan vi öppnade har vi varit stolta över att servera vår
                specialitet: handgjorda smashburgare och autentisk pinsa. Vi
                använder endast lokala och färska ingredienser för att garantera
                bästa möjliga smakupplevelse.
              </p>
              <a
                href={takeawayOrderSystem}
                target="_blank"
                rel="noopener noreferrer"
                className="secondary-button"
              >
                Beställ takeaway
              </a>
            </div>
            <div className="about-image">
              <img
                src="/images/kiosk.jpg"
                alt="Lilla Kiosken"
                className="about-img"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Stolt sponsrade av IconMMA */}
      <section className="about-section sponsor-section">
        <div className="container">
          <h2 className="section-title">Stolt sponsrade av IconMMA</h2>
          <div className="about-content">
            <div className="about-text">
              <p>
                ICON MMA är det främsta MMA-gymmet i Moreno Valley. Vi har över
                30 års erfarenhet inom alla aspekter av mixed martial arts.
              </p>
              <p>
                Vår omfattande anläggning erbjuder en professionell
                boxningsring, toppmodern träningsutrustning och redskap.
              </p>
              <p>
                ICON MMA är ett seriöst gym för professionella fighters, men vi
                är också ett familjegym som välkomnar människor i alla åldrar
                och med olika förmågor.
              </p>
              <p>
                Har du ett barn eller tonåring som behöver motion och
                vägledning? Kanske vill du komma i form och lära dig
                självförsvar.
              </p>
              <p>
                Oavsett om du letar efter ett träningspass eller en plats att
                bygga en karriär i UFC - vi vill gärna ha dig som en del av vår
                familj.
              </p>
              <div className="sponsor-buttons">
                <a
                  href="https://www.iconmma.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="secondary-button icon-button"
                >
                  IconMMA
                </a>
                <a
                  href="https://www.instagram.com/iconmma_/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="secondary-button icon-button"
                >
                  <FaInstagram /> Instagram
                </a>
              </div>
            </div>
            <div className="about-image">
              <img
                src="/images/iconmma.jpg"
                alt="IconMMA"
                className="about-img"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Information */}
      <section className="info-section">
        <div className="section-container">
          <h2 className="section-title">Hitta oss</h2>
          <div className="info-cards">
            <div className="info-card">
              <div className="info-icon">
                <FaMapMarkerAlt />
              </div>
              <h3>Adress</h3>
              <p>
                <a
                  href={mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address-link"
                >
                  Monvägen123 44343 Gråbo
                </a>
              </p>
            </div>

            <div className="info-card">
              <div className="info-icon">
                <FaPhone />
              </div>
              <h3>Kontakt</h3>
              <p>
                <a href={phoneLink} className="phone-link">
                  0302-415 40
                </a>
                <br />
              </p>
            </div>

            <div className="info-card">
              <div className="info-icon">
                <FaClock />
              </div>
              <h3>Öppettider</h3>
              <p>
                Mån-Fre: 11:30 - 20:00
                <br />
                Lör-Sön: 13:00 - 20:00
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to action */}
      <section className="cta-section">
        <div className="cta-content">
          <h2>Redo att beställa?</h2>
          <p>Klicka på knappen nedan för att gå till vårt beställningssystem</p>
          <a
            href={externalOrderSystem}
            target="_blank"
            rel="noopener noreferrer"
            className="order-button"
          >
            <FaShoppingCart /> Beställ nu
          </a>
        </div>
      </section>

      {/* Uppdaterad recensionssektion */}
      <section className="testimonials-section">
        <div className="container">
          <h2 className="section-title">Vad våra kunder säger</h2>

          <div className="update-reviews-container">
            <button
              onClick={() => fetchReviews()}
              className={`update-reviews-btn ${refreshing ? "refreshing" : ""}`}
              aria-label="Uppdatera recensioner"
              disabled={refreshing}
            >
              {refreshing ? "Uppdaterar..." : "Uppdatera"}
            </button>
          </div>

          {loading ? (
            <p className="loading-text">Laddar recensioner...</p>
          ) : (
            <div className="testimonials-grid">
              {reviews.map((review, index) => (
                <div
                  key={review.id}
                  className="testimonial-card"
                  style={{
                    animation: `fadeIn 0.5s ease-in-out ${index * 0.15}s both`,
                  }}
                >
                  <div className="review-header">
                    <span className="review-name">{review.name}</span>
                    <div
                      className="review-rating"
                      title={`Betyg: ${review.rating} av 5`}
                    >
                      {renderStars(review.rating)}
                    </div>
                  </div>
                  <div className="review-content">
                    <div className="review-text">
                      <p>{review.comment}</p>
                    </div>
                    <div className="review-images">
                      {getFirstImageUrl(review) && (
                        <img
                          src={getFirstImageUrl(review)}
                          alt={`Bild 1`}
                          className="review-image"
                          onClick={() => handleOpenModal(review)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="review-date">
                    {formatDate(review.createdAt)}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="testimonials-cta">
            <Link to="/recensioner" className="btn-secondary">
              Se alla recensioner
            </Link>
          </div>
        </div>
      </section>

      {/* Uppdaterad galleriisektion med lokala bilder */}
      <section className="gallery-section">
        <div className="section-container">
          <div className="gallery-grid">
            {[
              {
                image: "/images/smashburgare.jpg",
                title: "Smashburgare",
                alt: "Hamburgare med tillbehör",
                link: "https://app.ordine.se/r/lilla-kiosken-grabo",
              },
              {
                image: "/images/pinsa.jpg",
                title: "Pinsa",
                alt: "Pinsa/Pizza",
                link: "https://app.ordine.se/r/lilla-kiosken-grabo",
              },
              {
                image: "/images/tillbehor.jpg",
                title: "Tillbehör",
                alt: "Pommes frites och tillbehör",
                link: "https://app.ordine.se/r/lilla-kiosken-grabo",
              },
              {
                image: "/images/kebab2.jpg",
                title: "Kebab meny",
                alt: "Olika drycker",
                link: "https://app.ordine.se/r/lilla-kiosken-grabo",
              },
            ].map((item, index) => (
              <div className="gallery-item" key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link"
                  aria-label={`Beställ ${item.title}`}
                >
                  <img
                    src={item.image}
                    alt={item.alt}
                    className="gallery-image"
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "8px 8px 0 0",
                    }}
                    onError={(e) => {
                      console.error(`Kunde inte ladda bilden: ${item.image}`);
                      e.target.src = "/images/placeholder.jpg"; // Fallback till en platshållarbild om bilden inte kan laddas
                    }}
                  />
                  <div className="gallery-caption">{item.title}</div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal för att visa hela recensionen */}
      {modalReview && (
        <Modal onClose={handleCloseModal}>
          <div className="review-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <div className="modal-review-header">
              <span className="modal-review-name">{modalReview.name}</span>
              <span className="modal-review-rating">
                {renderStars(modalReview.rating)}
              </span>
            </div>
            <div className="modal-review-comment">{modalReview.comment}</div>
            <div className="modal-review-date">
              {formatDate(modalReview.createdAt)}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HomePage;
