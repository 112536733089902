import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import "./Menu.css";

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const categories = [
    { id: "all", name: "Alla" },
    { id: "smashburger", name: "Smash Burger" },
    { id: "pinsa", name: "Pinsa" },
    { id: "drinks", name: "Dricka" },
    { id: "sides", name: "Tillbehör" },
    { id: "dips", name: "Dipp" },
  ];

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const menuSnapshot = await getDocs(collection(db, "menu"));
        const items = menuSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMenuItems(items);
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMenu();
  }, []);

  useEffect(() => {
    console.log("Menu component rendered");
  }, []);

  const filteredItems =
    selectedCategory === "all"
      ? menuItems
      : menuItems.filter((item) => item.category === selectedCategory);

  if (loading) {
    return <div className="loading">Laddar meny...</div>;
  }

  return (
    <div className="menu-page">
      <div className="menu-container">
        <img
          src="/images/meny.jpg"
          alt="Lilla Kioskens Meny"
          className="menu-image"
          onError={(e) => {
            console.error("Error loading image");
            console.log("Image path:", e.target.src);
          }}
          onLoad={() => console.log("Image loaded successfully")}
        />
      </div>
    </div>
  );
};

export default Menu;
