import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "../firebase";
import "./ReviewsPage.css";

// Kontrollera att denna funktion körs vid sidladdning
console.log("ReviewsPage.js laddad");

// Funktion för att kontrollera om window är tillgängligt (client-side rendering)
const isClient = typeof window !== "undefined";
console.log("isClient:", isClient);

// Ta bort den globala funktionen och använd React state istället
if (isClient) {
  console.log("Sätter upp formatteringsfunktioner");

  // Funktion för att formatera datum
  window.formatDate = (date) => {
    if (!date) return "Okänt datum";

    const options = { year: "numeric", month: "long", day: "numeric" };
    if (date instanceof Date) {
      return date.toLocaleDateString("sv-SE", options);
    }

    try {
      // Försök konvertera Firebase Timestamp
      if (date.toDate) {
        return date.toDate().toLocaleDateString("sv-SE", options);
      }

      // Antar att det är en sträng eller timestamp
      return new Date(date).toLocaleDateString("sv-SE", options);
    } catch (e) {
      console.error("Fel vid datumformatering:", e);
      return "Okänt datum";
    }
  };

  // Funktion för att rendera stjärnor
  window.renderStarsHTML = (rating) => {
    const starCount = parseInt(rating) || 5;
    let stars = "";
    for (let i = 0; i < starCount; i++) {
      stars += "★";
    }
    for (let i = starCount; i < 5; i++) {
      stars += "☆";
    }
    return `<span class="review-stars" style="color: #ffd700; font-size: 25px; display: inline-block; margin: 0; padding: 0; line-height: 1; border: none; background: transparent; outline: none; box-shadow: none;">${stars}</span>`;
  };
}

const ReviewsPage = () => {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({
    name: "",
    rating: "5",
    comment: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [expandedReviewId, setExpandedReviewId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const reviewsRef = collection(db, "reviews");
      const q = query(reviewsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const reviewsData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        reviewsData.push({
          id: doc.id,
          name: data.name || "",
          rating: Number(data.rating) || 5,
          comment: data.comment || "",
          createdAt: data.createdAt?.toDate() || new Date(),
        });
      });

      setReviews(reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      alert("Kunde inte hämta recensioner. Försök igen senare.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      // Validera input
      if (!newReview.name.trim() || !newReview.comment.trim()) {
        alert("Vänligen fyll i alla fält");
        setSubmitLoading(false);
        return;
      }

      // Skapa recensionsobjekt
      const reviewData = {
        name: newReview.name.trim(),
        rating: Number(newReview.rating),
        comment: newReview.comment.trim(),
        createdAt: new Date(),
      };

      // Spara recensionen till Firestore
      await addDoc(collection(db, "reviews"), reviewData);

      // Återställ formuläret
      setNewReview({ name: "", rating: "5", comment: "" });

      // Uppdatera listan med recensioner
      await fetchReviews();

      alert("Tack för din recension!");
    } catch (error) {
      console.error("Error saving review:", error);
      alert("Ett fel uppstod: " + error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  // Funktion för att växla mellan utvidgat och normalt läge
  const toggleReviewExpansion = (reviewId) => {
    if (expandedReviewId === reviewId) {
      setExpandedReviewId(null);
    } else {
      setExpandedReviewId(reviewId);
      setTimeout(() => {
        const element = document.getElementById(`review-${reviewId}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  };

  const renderStars = (rating) => {
    const starCount = parseInt(rating) || 5;
    let stars = "";
    for (let i = 0; i < starCount; i++) {
      stars += "★";
    }
    for (let i = starCount; i < 5; i++) {
      stars += "☆";
    }
    return (
      <span
        className="review-stars"
        style={{
          color: "#ffd700",
          fontSize: "25px",
          display: "inline-block",
          margin: 0,
          padding: 0,
          lineHeight: 1,
          border: "none",
          background: "transparent",
          outline: "none",
          boxShadow: "none",
        }}
      >
        {stars}
      </span>
    );
  };

  const isTextLong = (text) => {
    return text.length > 100;
  };

  // Lägg till lyssnare för skärmstorleksändringar
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchReviews();

    // Inga DOM-manipulationer för modaler
    document.body.classList.add("reviews-page");

    return () => {
      document.body.classList.remove("reviews-page");
    };
  }, []);

  if (loading) {
    return (
      <div className="reviews-page">
        <div className="reviews-container">
          <h1>Recensioner</h1>
          <div className="loading">Laddar recensioner...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="reviews-page">
      <div className="reviews-container">
        <h1>Dela din mat upplevelse!</h1>

        <form onSubmit={handleSubmit} className="review-form">
          <input
            type="text"
            placeholder="Ditt namn"
            value={newReview.name}
            onChange={(e) =>
              setNewReview({ ...newReview, name: e.target.value })
            }
            required
            className="review-input"
          />

          <select
            value={newReview.rating}
            onChange={(e) =>
              setNewReview({ ...newReview, rating: e.target.value })
            }
            className="review-input"
          >
            <option value="5">5 stjärnor</option>
            <option value="4">4 stjärnor</option>
            <option value="3">3 stjärnor</option>
            <option value="2">2 stjärnor</option>
            <option value="1">1 stjärna</option>
          </select>

          <textarea
            placeholder="Din recension"
            value={newReview.comment}
            onChange={(e) =>
              setNewReview({ ...newReview, comment: e.target.value })
            }
            required
            className="review-input"
          />

          <button
            type="submit"
            className="submit-button"
            disabled={submitLoading}
          >
            {submitLoading ? "Skickar..." : "Skicka recension"}
          </button>
        </form>

        <div className="reviews-grid">
          {reviews.map((review) => {
            const isExpanded = expandedReviewId === review.id;

            return (
              <div
                key={review.id}
                id={`review-${review.id}`}
                className={`review-card ${isExpanded ? "expanded" : ""}`}
              >
                <div className="review-header">
                  <span className="review-name">{review.name}</span>
                  <span className="review-rating">
                    {renderStars(review.rating)}
                  </span>
                </div>

                <div className="review-comment-container">
                  <div
                    className="review-comment"
                    style={{
                      maxHeight: isExpanded ? "none" : "80px",
                      overflow: isExpanded ? "visible" : "hidden",
                    }}
                  >
                    {review.comment}
                  </div>

                  {isTextLong(review.comment) && (
                    <div className="view-full-review-button">
                      <button onClick={() => toggleReviewExpansion(review.id)}>
                        {isExpanded ? "Visa mindre" : "Visa mer"}
                        <span>▼</span>
                      </button>
                    </div>
                  )}
                </div>

                <div className="review-date">
                  {formatDate(review.createdAt)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;

// Hjälpfunktion för formatering utanför komponenten
const formatDate = (date) => {
  if (!date) return "";

  try {
    // Försök konvertera Firebase Timestamp
    if (date.toDate) {
      date = date.toDate();
    }

    // Formatera datumet
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("sv-SE", options);
  } catch (e) {
    console.error("Fel vid datumformatering:", e);
    return "";
  }
};
