import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getAdminToken, removeAdminToken } from "../admin/services/adminApi";

// Skapa en context för admin-status
const AdminContext = createContext();

// Hook för att använda admin context
export const useAdminContext = () => {
  return useContext(AdminContext);
};

// Provider komponenten som innehåller admin-state
export const AdminProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // När komponenten laddas, kontrollera local storage för eventuell inloggningsstatus
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("adminLoggedIn");
    if (storedLoginStatus === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  // Funktion för att logga in
  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem("adminLoggedIn", "true");
  };

  // Funktion för att logga ut
  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("adminLoggedIn");
  };

  // Värdet som ska exponeras i contexten
  const value = {
    isLoggedIn,
    login,
    logout,
  };

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

export default AdminProvider;
