import React, { useState, useEffect, useMemo } from "react";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase/config";
import "./CompletionsList.css";

const CompletionsList = () => {
  const [completions, setCompletions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompletions = async () => {
      try {
        const completionsQuery = query(
          collection(db, "completions"),
          orderBy("date", "desc"),
          limit(10)
        );

        const snapshot = await getDocs(completionsQuery);
        const completionsList = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            playerName:
              data.playerName ||
              `${data.firstName || ""} ${data.lastName || ""}`.trim(),
          };
        });

        setCompletions(completionsList);
      } catch (error) {
        console.error("Error fetching completions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletions();
  }, []);

  // Sortera och kategorisera deltagare
  const sortedCompletions = useMemo(() => {
    // Först, filtrera bort de som saknar tid
    const withTime = completions.filter((c) => c.time && c.time.trim() !== "");
    const withoutTime = completions.filter(
      (c) => !c.time || c.time.trim() === ""
    );

    // Sortera efter tid (konvertera tid till jämförbart format)
    const sorted = [...withTime].sort((a, b) => {
      // Försök extrahera minuter och sekunder
      const getTimeInSeconds = (timeStr) => {
        const minutes = timeStr.match(/(\d+)\s*min/i);
        const seconds = timeStr.match(/(\d+)\s*sek/i);
        return (
          (minutes ? parseInt(minutes[1]) * 60 : 0) +
          (seconds ? parseInt(seconds[1]) : 0)
        );
      };

      const timeA = getTimeInSeconds(a.time);
      const timeB = getTimeInSeconds(b.time);

      return timeA - timeB; // Sortera stigande (snabbast först)
    });

    // Lägg till de utan tid sist
    return [...sorted, ...withoutTime].map((completion, index) => ({
      ...completion,
      rank: index + 1,
      tier: Math.min(
        Math.ceil((index + 1) / (sorted.length > 10 ? sorted.length / 10 : 1)),
        10
      ),
    }));
  }, [completions]);

  if (loading) {
    return <div className="completions-loading">Laddar Wall of Fame...</div>;
  }

  return (
    <div className="completions-list">
      {sortedCompletions.length > 0 ? (
        <table className="completions-table">
          <thead>
            <tr>
              <th className="rank-column"></th>
              <th>Namn</th>
              <th>Stad</th>
              <th>Utmaning</th>
              <th>Tid</th>
            </tr>
          </thead>
          <tbody>
            {sortedCompletions.map((completion) => (
              <tr key={completion.id}>
                <td className="rank-column">
                  <div className={`rank-badge tier-${completion.tier}`}>
                    {completion.rank}
                  </div>
                </td>
                <td className="completion-name">{completion.playerName}</td>
                <td className="completion-city">{completion.city || "-"}</td>
                <td
                  className={`challenge-${
                    completion.challengeDifficulty?.toLowerCase() || "medium"
                  }`}
                >
                  {completion.challengeName}
                </td>
                <td className="completion-time">{completion.time || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-completions">Inga genomförda utmaningar än.</div>
      )}
    </div>
  );
};

export default CompletionsList;
