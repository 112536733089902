import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import "./AdminLogin.css";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Kontrollera om användaren redan är inloggad via Firebase
    const auth = getAuth();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Användaren är inloggad, spara extra information i sessionStorage
        // för att behålla det genom sidnavigering
        sessionStorage.setItem("adminToken", user.uid);
        sessionStorage.setItem("adminEmail", user.email);

        // Navigera till utmaningar
        navigate("/admin/utmaningar");
      }
    });

    // Städa upp lyssnaren när komponenten avmonteras
    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Vänligen fyll i både e-post och lösenord.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const auth = getAuth();

      // Använd sessionPersistence så att användaren förblir inloggad tills
      // browserfönstret stängs
      await setPersistence(auth, browserSessionPersistence);

      // Logga in användaren
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Spara användardata i sessionStorage för åtkomst på andra sidor
      sessionStorage.setItem("adminToken", userCredential.user.uid);
      sessionStorage.setItem("adminEmail", userCredential.user.email);

      console.log("Inloggning lyckades, navigerar till admin/utmaningar");

      // Navigera till utmaningar
      navigate("/admin/utmaningar");
    } catch (error) {
      console.error("Login error:", error);

      let errorMessage = "Ett fel uppstod vid inloggning.";

      // Visa mer specifika felmeddelanden
      if (error.code === "auth/invalid-email") {
        errorMessage = "Ogiltig e-postadress.";
      } else if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        errorMessage = "Fel e-post eller lösenord.";
      } else if (error.code === "auth/too-many-requests") {
        errorMessage = "För många inloggningsförsök. Försök igen senare.";
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <div className="admin-login-header">
          <h1>Lilla Kiosken</h1>
          <h2>Admin Login</h2>
        </div>

        {error && <div className="admin-login-error">{error}</div>}

        <form onSubmit={handleLogin} className="admin-login-form">
          <div className="admin-login-input-group">
            <label htmlFor="email">E-post</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              autoComplete="email"
              placeholder="admin@example.com"
              required
            />
          </div>

          <div className="admin-login-input-group">
            <label htmlFor="password">Lösenord</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              autoComplete="current-password"
              placeholder="••••••••"
              required
            />
          </div>

          <button
            type="submit"
            className="admin-login-button"
            disabled={loading}
          >
            {loading ? "Loggar in..." : "Logga in"}
          </button>
        </form>

        <div className="admin-login-footer">
          <p>Endast för behörig personal</p>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
