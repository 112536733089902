import React, { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase/config";
import CompletionsList from "../components/CompletionsList";
import "./Utmaningar.css";

const Utmaningar = () => {
  const [completions, setCompletions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompletions = async () => {
      try {
        const q = query(collection(db, "completions"), orderBy("time", "asc"));
        const querySnapshot = await getDocs(q);

        const completionsData = [];
        querySnapshot.forEach((doc) => {
          completionsData.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        // Lägg till rankingar
        const rankedCompletions = completionsData.map((completion, index) => ({
          ...completion,
          rank: index + 1,
        }));

        setCompletions(rankedCompletions);
      } catch (error) {
        console.error("Error fetching completions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletions();
  }, []);

  return (
    <div className="utmaningar-page">
      <div className="utmaningar-container">
        <h1></h1>

        <div className="utmaningar-content">
          <div className="wall-of-fame">
            <h2>Wall of Fame</h2>

            {loading ? (
              <div className="loading">Laddar utmaningar...</div>
            ) : (
              <CompletionsList completions={completions} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Utmaningar;
