import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  FaTags,
  FaClock,
  FaInfoCircle,
  FaRegHeart,
  FaHeart,
} from "react-icons/fa";
import "./Offers.css";

function Offers() {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [favoriteOffers, setFavoriteOffers] = useState([]);
  const [showExpired, setShowExpired] = useState(false);
  const [redeemModal, setRedeemModal] = useState({
    show: false,
    offer: null,
    code: "",
  });

  useEffect(() => {
    // Hämta sparade favoriter från localStorage
    const savedFavorites = localStorage.getItem("favoriteOffers");
    if (savedFavorites) {
      setFavoriteOffers(JSON.parse(savedFavorites));
    }

    fetchOffers();
  }, [showExpired]);

  useEffect(() => {
    if (offers.length > 0) {
      console.log("Hämtade erbjudanden med bilddata:");
      offers.forEach((offer) => {
        console.log(`Erbjudande "${offer.title}":`, {
          id: offer.id,
          imageUrl: offer.imageUrl,
          hasImage: !!offer.imageUrl,
        });
      });
    }
  }, [offers]);

  const fetchOffers = async () => {
    try {
      setLoading(true);

      // Hämta alla erbjudanden från Firestore
      const allOffersQuery = query(
        collection(db, "offers"),
        orderBy("createdAt", "desc")
      );

      const querySnapshot = await getDocs(allOffersQuery);
      const allOffersList = [];

      querySnapshot.forEach((doc) => {
        const offerData = { id: doc.id, ...doc.data() };
        console.log("Hämtat erbjudande:", offerData); // Debug-loggning
        allOffersList.push(offerData);
      });

      // Filtrera erbjudanden baserat på utgångsdatum och active-flaggan
      const currentDate = new Date();

      const filteredOffers = allOffersList.filter((offer) => {
        // Kontrollera om erbjudandet är utgånget baserat på validUntil
        const isExpired = offer.validUntil
          ? (offer.validUntil.toDate
              ? offer.validUntil.toDate()
              : new Date(offer.validUntil)) < currentDate
          : false;

        // Om showExpired är true, visa alla erbjudanden
        if (showExpired) {
          return true;
        }
        // Annars, visa bara aktiva erbjudanden som inte är utgångna
        else {
          return offer.active && !isExpired;
        }
      });

      console.log("Filtrerade erbjudanden:", filteredOffers.length);
      setOffers(filteredOffers);
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "Inget slutdatum";

    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return new Intl.DateTimeFormat("sv-SE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  const isExpired = (validUntil) => {
    if (!validUntil) return false;

    const validDate = validUntil.toDate
      ? validUntil.toDate()
      : new Date(validUntil);
    const now = new Date();

    return validDate < now;
  };

  const calculateDiscount = (price, regularPrice) => {
    if (!price || !regularPrice || regularPrice <= price) return null;

    const discount = Math.round(((regularPrice - price) / regularPrice) * 100);
    return discount;
  };

  const toggleFavorite = (offerId) => {
    let updatedFavorites;

    if (favoriteOffers.includes(offerId)) {
      updatedFavorites = favoriteOffers.filter((id) => id !== offerId);
    } else {
      updatedFavorites = [...favoriteOffers, offerId];
    }

    setFavoriteOffers(updatedFavorites);
    localStorage.setItem("favoriteOffers", JSON.stringify(updatedFavorites));
  };

  // Lägg till en hjälpfunktion för att hantera CORS
  const getProxiedImageUrl = (url) => {
    // Använd bara i utvecklingsmiljö
    if (process.env.NODE_ENV === "development") {
      // Använd olika CORS-proxies (om en misslyckas kan du prova en annan)
      // return `https://cors-anywhere.herokuapp.com/${url}`;
      return `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`;
    }
    return url; // I produktion, använd originalet
  };

  // Lägg till en funktion för att beräkna och visa besparingar
  const calculateSavings = (regularPrice, price) => {
    if (!regularPrice || !price || regularPrice <= price) {
      return null;
    }

    const savings = regularPrice - price;
    const savingsPercent = Math.round((savings / regularPrice) * 100);

    return {
      amount: savings,
      percent: savingsPercent,
    };
  };

  // Lägg till en ny funktion för att hantera klick på CTA-knappen
  const handleRedeemOffer = (offer) => {
    // Generera en unik kod baserat på erbjudandets ID och datum
    const uniqueCode = `${offer.id.substring(0, 6)}-${new Date()
      .getTime()
      .toString()
      .substring(9)}`;

    // Visa information i en modal eller alert
    setRedeemModal({
      show: true,
      offer: offer,
      code: uniqueCode,
    });
  };

  return (
    <div className="offers-page">
      <div className="offers-hero">
        <div className="container hero-content">
          <h1 className="hero-title">Veckans Erbjudanden</h1>
          <p className="hero-subtitle">
            Ta del av våra exklusiva erbjudanden och spara pengar på din
            favoritmat!
          </p>
        </div>
      </div>

      <div className="container main-content">
        <div className="offers-controls">
          <div className="offers-info">
            <FaInfoCircle />
            <span>
              {offers.length === 0
                ? "Inga erbjudanden att visa"
                : `${offers.length} erbjudanden ${
                    showExpired ? "(inklusive utgångna)" : ""
                  }`}
            </span>
          </div>

          <div className="toggle-container">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={showExpired}
                onChange={() => setShowExpired(!showExpired)}
              />
              <span className="toggle-slider"></span>
            </label>
            <span>Visa {showExpired ? "alla" : "utgångna"} erbjudanden</span>
          </div>
        </div>

        {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Laddar erbjudanden...</p>
          </div>
        ) : offers.length === 0 ? (
          <div className="no-offers">
            <FaTags className="no-offers-icon" />
            <h2>Inga erbjudanden tillgängliga just nu</h2>
            <p>Kom tillbaka snart för nya spännande erbjudanden!</p>
          </div>
        ) : (
          <div className="offers-grid">
            {offers.map((offer) => {
              const expired = isExpired(offer.validUntil);
              const discount = calculateDiscount(
                offer.price,
                offer.regularPrice
              );
              const isFavorite = favoriteOffers.includes(offer.id);
              const savings = calculateSavings(offer.regularPrice, offer.price);

              return (
                <div
                  key={offer.id}
                  className={`offer-card ${expired ? "expired" : ""}`}
                >
                  {expired && (
                    <div className="expired-badge">
                      <span>Utgått</span>
                    </div>
                  )}

                  {discount && !expired && (
                    <div className="discount-badge">
                      <span>{discount}%</span>
                    </div>
                  )}

                  <div
                    className="favorite-button"
                    onClick={() => toggleFavorite(offer.id)}
                  >
                    {isFavorite ? <FaHeart /> : <FaRegHeart />}
                  </div>

                  <div className="offer-content">
                    <h2 className="offer-title">{offer.title}</h2>
                    <p className="offer-description">
                      {offer.description || "Ingen beskrivning tillgänglig."}
                    </p>

                    {offer.validUntil && (
                      <div className="valid-until">
                        <FaClock />
                        <span>
                          Giltigt till: {formatDate(offer.validUntil)}
                        </span>
                      </div>
                    )}

                    {offer.price && (
                      <div className="offer-price-container">
                        <div className="price-tag">
                          <span className="price">{offer.price} kr</span>
                          {offer.regularPrice && (
                            <span className="regular-price">
                              {offer.regularPrice} kr
                            </span>
                          )}
                        </div>

                        {savings && (
                          <div className="savings-highlight">
                            <div className="savings-badge">
                              SPARA {savings.percent}%
                            </div>
                            <p className="savings-amount">
                              Du sparar {savings.amount} kr
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="offers-info-section">
        <div className="container">
          <div className="info-content">
            <h2>Om våra erbjudanden</h2>
            <p>
              Våra veckoerbjudanden ger dig möjlighet att prova våra läckra
              rätter till ett förmånligt pris. Erbjudandena gäller endast under
              den angivna perioden och kan inte kombineras med andra rabatter.
            </p>
            <p>
              Glöm inte att följa oss på sociala medier för att vara först med
              att se nya erbjudanden och specialkampanjer!
            </p>
          </div>
        </div>
      </div>

      {redeemModal.show && redeemModal.offer && (
        <div className="redeem-modal-overlay">
          <div className="redeem-modal">
            <button
              className="close-button"
              onClick={() =>
                setRedeemModal({ show: false, offer: null, code: "" })
              }
            >
              ×
            </button>

            <h2>Utnyttja erbjudande</h2>
            <h3>{redeemModal.offer.title}</h3>

            <div className="redeem-details">
              <p>Visa denna kod i kassan för att utnyttja erbjudandet:</p>
              <div className="redeem-code">{redeemModal.code}</div>

              <div className="redeem-info">
                <p>
                  <strong>Pris:</strong> {redeemModal.offer.price} kr
                </p>
                {redeemModal.offer.regularPrice && (
                  <p>
                    <strong>Ordinarie pris:</strong>{" "}
                    {redeemModal.offer.regularPrice} kr
                  </p>
                )}
                {redeemModal.offer.validUntil && (
                  <p>
                    <strong>Giltigt till:</strong>{" "}
                    {redeemModal.offer.validUntil.toDate
                      ? redeemModal.offer.validUntil
                          .toDate()
                          .toLocaleDateString("sv-SE")
                      : new Date(
                          redeemModal.offer.validUntil
                        ).toLocaleDateString("sv-SE")}
                  </p>
                )}
              </div>

              <button
                className="save-button"
                onClick={() => {
                  // Du kan implementera funktioner för att spara kod, skicka via mail etc.
                  alert(
                    "Kod kopierad! Visa denna vid besök för att få ditt erbjudande."
                  );
                  // Kopiera till urklipp
                  navigator.clipboard
                    .writeText(redeemModal.code)
                    .catch((err) =>
                      console.error("Kunde inte kopiera kod: ", err)
                    );
                }}
              >
                Spara kod
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Offers;
